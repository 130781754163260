import type {LanguageCode} from '../i18n.ts';
import {BASE_API_URL, WEBDB_S3_URL} from '../envs.ts';

export interface B2BModuleInfo {
  key: string;
  sp_title: string;
  children: B2BModuleInfo[];
}

export interface B2BStaticPage {
  key: string;
  sp_title: string;
  sp_summary: string;
  content: string;
  children: B2BModuleInfo[];
}

export type B2bSearchResult = {
  matchingValue: string;
  title: string;
  titleShort: string;
};

export async function fetchB2BHierarchy(
  lang: LanguageCode,
): Promise<B2BStaticPage[]> {
  const propagatedParams =
    typeof document !== 'undefined' ? document.location.search : '';
  const mergedUrl = `${WEBDB_S3_URL}/b2b_merged/${lang}/b2b_sp_hierarchy.json${propagatedParams}`;
  return fetch(mergedUrl)
    .then((r) => (r.ok ? r.json() : null))
    .then((r) => {
      return r;
    });
}

export async function b2bSearch(
  qtext: string,
  lang: LanguageCode,
): Promise<B2bSearchResult[]> {
  return fetch(`${BASE_API_URL}/api/6/b2b_search?qtext=${qtext}&lang=${lang}`)
    .then((r) => (r.ok ? r.json() : null))
    .then((r) => {
      return r;
    });
}
