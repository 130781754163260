import {html, LitElement} from 'lit';
// @ts-ignore
import Autocomplete from '@trevoreyre/autocomplete-js';
import {localized, msg} from '@lit/localize';
import {customElement, state} from 'lit/decorators.js';
import type {B2bSearchResult} from '../../apis/api-b2b.ts';
import {b2bSearch} from '../../apis/api-b2b.ts';
import {getLanguageCode} from '../../i18n.ts';
import type {AutocompleteProps} from '../element-search.ts';
import {crossSvg, divider, searchSvg} from '../../helpers/elements.ts';
import {replaceAll} from '../../helpers/string.ts';
import {ElementB2bSearchResult} from './element-b2b-search-result.ts';
import {createStaticPageUrl} from '../../urlHandling/urlCreate.ts';
import {Router} from '@vaadin/router';
import {sendAnalytics} from '../../analytics.ts';

@localized()
@customElement('element-b2b-search')
export class ElementB2bSearch extends LitElement {
  @state() counter: number = 0;
  @state() inputValue = '';
  @state() showAll = false;
  autocomplete: Autocomplete;
  private analyticsSearchTimeout: NodeJS.Timeout | undefined = undefined;

  override firstUpdated() {
    this.autocomplete = new Autocomplete(this, {
      debounceTime: 200,
      search: (input: string): Promise<B2bSearchResult[]> => {
        return new Promise((resolve) => {
          if (input.length >= 1) {
            if (this.analyticsSearchTimeout) {
              clearTimeout(this.analyticsSearchTimeout);
            }
            this.analyticsSearchTimeout = setTimeout(() => {
              sendAnalytics('event', 'search_query', {
                event_label: this.inputValue,
              });
            }, 2000);

            b2bSearch(input, getLanguageCode()).then((results) => {
              this.counter = results.length;
              resolve(results);
            });
          } else {
            resolve([]);
          }
        });
      },

      renderResult: (result: B2bSearchResult, props: AutocompleteProps) => {
        if (props['data-result-index'] > 7 && !this.showAll) return '';
        const resultElem = new ElementB2bSearchResult();
        resultElem.result = result;
        resultElem.parent = this;
        resultElem.props = props;
        resultElem.className = 'col-span-10 xxl:col-span-8';
        resultElem.addEventListener('', () => this.onShowAll());
        resultElem.addEventListener('', () => this.onShowLess());
        return resultElem;
      },

      getResultValue: (result: B2bSearchResult) => {
        return replaceAll(replaceAll(result.title, '<b>', ''), '</b>', '');
      },

      onSubmit: (result: B2bSearchResult) => {
        if (!result) return;
        sendAnalytics('event', 'search_select', {
          event_label: `${result.titleShort}`,
        });
        const lang = getLanguageCode();
        Router.go(createStaticPageUrl(lang, result.titleShort));
        this.closeSearch();
      },
    });

    this.autocomplete.input.removeEventListener(
      'blur',
      this.autocomplete.core.handleBlur,
    );
    this.autocomplete.input.focus();
    this.addEventListener('keydown', (e) => {
      if (e.code === 'Escape') this.closeSearch();
    });

    // Keep search results displayed after enter or tab
    this.querySelector('input').addEventListener('keydown', (e) => {
      if (e.code === 'Enter' || e.code === 'Tab')
        this.autocomplete.core.updateResults(this.autocomplete.input.value);
    });
  }

  onShowAll() {
    this.showAll = true;
    this.autocomplete.core.updateResults(this.autocomplete.input.value);
  }

  onShowLess() {
    this.showAll = false;
    this.autocomplete.core.updateResults(this.autocomplete.input.value);
  }

  closeSearch() {
    this.dispatchEvent(new CustomEvent('close'));
  }

  inputChanged(e: InputEvent) {
    this.inputValue = (e.target as HTMLInputElement).value;
  }

  resetInputField() {
    this.querySelector('input').value = '';
    this.autocomplete.core.updateResults(this.autocomplete.input.value);
    this.inputValue = '';
  }

  override connectedCallback() {
    if (this.autocomplete?.input) this.autocomplete.input.focus();
    super.connectedCallback();
  }

  override render() {
    return html`
      <div class="w-full shadow-search bg-chm-tertiary-gray">
        <div class="w-content mx-auto grid grid-cols-10 gap-x-4">
          <div class="col-span-10 flex h-[80px] items-center justify-center">
            ${searchSvg(
              'h-header-icon-s sm:h-header-icon-m mdxx:h-header-icon-l mr-[22px] shrink-0',
            )}
            <input
              type="text"
              class="field-font h-[56px] min-w-[75px] flex-auto shrink p-4 outline-none"
              placeholder="${msg('Search…')}"
              @input="${(e: InputEvent) => this.inputChanged(e)}"
            />
            <button
              class="-ml-[32px] mr-[16px] md:-ml-[40px] md:mr-[20px]"
              @click="${() => this.resetInputField()}"
            >
              ${crossSvg(
                'h-logo-chm-plus md:h-header-icon-s fill-chm-red mouse:hover:fill-chm-focus-red cursor-pointer',
              )}
            </button>
            <button class="ml-[20px]" @click="${() => this.closeSearch()}">
              ${crossSvg(
                'h-header-icon-s sm:h-header-icon-m mdxx:h-header-icon-l fill-chm-red mouse:hover:fill-chm-focus-red cursor-pointer',
              )}
            </button>
          </div>
          ${divider(
            '100vw',
            '1px',
            '0 0',
            '#DEE2E6',
            'absolute z-50 left-0 top-[80px]',
          )}
          <div class="w-content z-50">
            <ul
              class="grid max-h-[70vh] !w-[inherit] max-w-[inherit] grid-cols-10 gap-4 overflow-y-auto bg-chm-tertiary-gray shadow-search"
            ></ul>
          </div>
        </div>
      </div>
    `;
  }

  override createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'element-b2b-search': ElementB2bSearch;
  }
}
