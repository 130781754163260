import {localized, msg} from '@lit/localize';
import {customElement, property} from 'lit/decorators.js';
import {html, LitElement} from 'lit';
import type {AutocompleteProps} from '../element-search.ts';
import {classMap} from 'lit/directives/class-map.js';
import type {B2bSearchResult} from '../../apis/api-b2b.ts';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {escapeRegExp, replaceAll} from '../../helpers/string.ts';
import {minusSvg, plusSvg} from '../../helpers/elements.ts';
import type {ElementB2bSearch} from './element-b2b-search.ts';

@localized()
@customElement('element-b2b-search-result')
export class ElementB2bSearchResult extends LitElement {
  @property({type: Object}) result: B2bSearchResult;
  @property({type: Object}) props: AutocompleteProps;
  @property({type: Object}) parent: ElementB2bSearch;

  override render() {
    const searchTerm = this.parent.autocomplete.input.value;
    const matchingValue = this.result.matchingValue;

    const regexp = new RegExp(`${escapeRegExp(searchTerm)}(?![^<>]*>)`, 'ig');

    const words = matchingValue.split(' ');

    let snippetArray: string[] = [];

    if (words.length > 2) {
      const hIndex = words.findIndex((w) =>
        w.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      const firstPart = hIndex > 0 ? words.slice(0, hIndex).join(' ') : '';
      const searchedWord = words[hIndex];
      const secondPart = words.slice(hIndex + 1, words.length).join(' ');
      snippetArray = [firstPart, searchedWord, secondPart];
    } else {
      snippetArray = [words.join(' ')];
    }
    snippetArray = snippetArray.map((s) => {
      s = replaceAll(s, '<b>', '');
      return s.replace(
        regexp,
        (match: string) =>
          `<span class='font-bold bg-chm-light-gray'>${match}</span>`,
      );
    });

    const addShowAllBtn =
      !this.parent.showAll && this.props['data-result-index'] === 7;
    const addShowLessBtn =
      this.parent.showAll &&
      this.props['data-result-index'] === this.parent.counter - 1;

    return html`
      ${this.props['data-result-index'] > 0
        ? ''
        : html` <li
            class="distinction py-[16px] md:py-[24px] xl:py-[32px] mt-[16px] md:mt-[24px] lg:mt-[32px] mx-[42px] sm:mx-[54px] mdxx:mx-[70px] flex items-center font-bold"
          >
            ${this.parent.counter}
            ${msg('Results found', {desc: 'b2b search results number'})}
          </li>`}
      <li
        id="${this.props.id}"
        data-result-index="${this.props['data-result-index']}"
        role="${this.props.role}"
        tabindex="0"
        class="autocomplete-result md:search-result-m ${classMap({
          hidden: !this.parent.showAll && this.props['data-result-index'] > 7,
          'border-b-[1px]': !addShowAllBtn && !addShowLessBtn,
          'border-b-chm-light-gray': !addShowAllBtn && !addShowLessBtn,
        })} mx-[42px] cursor-pointer select-none text-search-result-s sm:mx-[54px] mdxx:mx-[70px]"
        @keydown="${(e: KeyboardEvent) => {
          if (e.key === 'Enter') (<HTMLElement>e.target).click();
        }}"
      >
        <div
          class="grid min-h-[40px] grid-cols-4 items-center text-body sm:text-sm-body md:grid-cols-8 md:text-md-body md:gap-x-2"
        >
          <div
            class="col-span-4 flex pb-[12px] pt-[8px] md:col-span-4 md:items-center"
          >
            <div class="mt-[4px] flex flex-col md:mt-0 w-full">
              <div class="flex whitespace-nowrap w-full">
                ${snippetArray.length > 1
                  ? html` <div
                        class="left-ellipsis"
                        .hidden="${!snippetArray[0].length}"
                      >
                        <span>${unsafeHTML(snippetArray[0])}&nbsp;</span>
                      </div>
                      <div>${unsafeHTML(snippetArray[1])}&nbsp;</div>
                      <div class="text-ellipsis overflow-hidden">
                        ${unsafeHTML(snippetArray[2])}
                      </div>`
                  : html` <div>${unsafeHTML(snippetArray[0])}</div>`}
              </div>
              <div class="md:hidden">${this.result.title}</div>
            </div>
          </div>
          <div
            class="hidden md:col-span-3 md:col-start-6 md:block md:ml-[16px] lg:ml-[24px]"
          >
            ${this.result.title}
          </div>
        </div>
      </li>
      ${addShowAllBtn
        ? html` <li
            tabindex="0"
            class="mx-[42px] flex py-[16px] cursor-pointer items-center text-url sm:mx-[54px] md:text-md-url mdxx:mx-[70px]"
            @click="${() => this.parent.onShowAll()}"
            @keydown="${(e: KeyboardEvent) => {
              if (e.key === 'Enter') this.parent.onShowAll();
            }}"
          >
            ${plusSvg('w-[18px] h-[18px] fill-chm-red mr-[11px]')}
            ${msg('Show all')}
          </li>`
        : ''}
      ${addShowLessBtn
        ? html` <li
            tabindex="0"
            class="mx-[42px] flex py-[16px] cursor-pointer items-center text-url sm:mx-[54px] md:text-md-url mdxx:mx-[70px]"
            @click="${() => this.parent.onShowLess()}"
            @keydown="${(e: KeyboardEvent) => {
              if (e.key === 'Enter') this.parent.onShowLess();
            }}"
          >
            ${minusSvg('w-[18px] h-[18px] fill-chm-red mr-[11px]')}
            ${msg('Show less')}
          </li>`
        : ''}
    `;
  }

  override createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'element-b2b-search-result': ElementB2bSearchResult;
  }
}
