import {Router} from '@vaadin/router';
import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import type {Ref} from 'lit/directives/ref.js';
import {ref, createRef} from 'lit/directives/ref.js';
import {routes} from './b2b-router.ts';
import {loadFooter} from './initialization/footerLoader.ts';
import '../src/elements/b2b/b2b-element-header.ts';
import {normalizeB2bUrl} from './urlHandling/urlNormalizer.ts';
import {resolveDeployPrefix} from './initialization/deployPrefixResolver.ts';
import {defaultB2bLanguage} from './unspecific/languageUtils.ts';
import './elements/element-big-gallery.ts';
import {initializeAnalytics} from './analytics.ts';
import {isB2bPage$} from './stores/store-b2b.ts';
import {initializeViewHeight} from './vhHack.ts';

main();

async function main() {
  initializeAnalytics();

  const deployPrefix = resolveDeployPrefix();
  const initialURL = document.location.toString();
  const normalizedURL = normalizeB2bUrl(
    initialURL,
    deployPrefix,
    defaultB2bLanguage,
  );
  if (normalizedURL && normalizedURL !== initialURL) {
    window.history.replaceState(null, undefined, normalizedURL);
  }
  initializeViewHeight();
}

@customElement('b2b-index')
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class B2BIndex extends LitElement {
  outletRef: Ref<HTMLElement> = createRef();

  override render() {
    return html`
      <b2b-element-header></b2b-element-header>
      <div
        id="main"
        class="overflow-overlay h-screen-wo-header-b2b overflow-x-hidden"
      >
        <div ${ref(this.outletRef)}></div>
        <element-footer .b2bPage=${true}></element-footer>
      </div>
      <element-big-gallery></element-big-gallery>
    `;
  }

  override async firstUpdated() {
    const router = new Router();
    router.setOutlet(this.outletRef.value);
    loadFooter(this.outletRef.value!);
    router.setRoutes(routes);
    isB2bPage$.next(true);
  }

  override createRenderRoot() {
    return this;
  }
}
