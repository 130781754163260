import {b2bLanguageCodes, languageCodes} from '../i18n.ts';
import type {LanguageCode, B2bLanguageCode} from '../i18n.ts';
/**
 * Use the navigator languages to choose which language is the best for the user.
 * @param appOK A list of accepted languages (2 characters, lowercase, like fr, en, ...)
 * @return the first acceptable language
 */
export function detectOKLanguage(
  appOK: string[],
  navigator: Navigator,
): string | undefined {
  const languages = navigator.languages || [navigator.language];
  const userOK = languages.map((item) => item.substring(0, 2));
  const ok = userOK.filter((item) => appOK.includes(item));
  return ok[0]; // this is undefined when the array is empty
}

export const defaultLanguage = (detectOKLanguage(
  languageCodes as unknown as string[],
  window.navigator,
) || 'en') as LanguageCode;

export const defaultB2bLanguage = (detectOKLanguage(
  b2bLanguageCodes as unknown as string[],
  window.navigator,
) || 'de') as B2bLanguageCode;
