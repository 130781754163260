import type {LitElement} from 'lit';
import {monitorLoadingError} from './obsoleteApi.ts';

export function loadFooter(outlet: HTMLElement) {
  const observer = new MutationObserver(async (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        await (<LitElement>outlet.firstChild).updateComplete;
        monitorLoadingError(import('../elements/element-footer.ts'));
        observer.disconnect();
      }
    }
  });

  observer.observe(outlet, {
    attributes: false,
    childList: true,
    subtree: false,
  });
}
