/*
 * Render an address
 */
import {html, LitElement, nothing} from 'lit';
import {customElement, state} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import {breadcrumbArrowSvg, arrowSvg} from '../../helpers/elements.ts';
import {splitClassList} from '../../helpers/classMap.ts';
import {msg} from '@lit/localize';
import './element-b2b-socials.ts';
import '../components/element-button.ts';
import type {B2BStaticPage, B2BModuleInfo} from '../../apis/api-b2b.ts';
import {fetchB2BHierarchy} from '../../apis/api-b2b.ts';
import {getLanguageCode} from '../../i18n.ts';
import type {LanguageCode} from '../../i18n.ts';
import {b2bPageHierarchy$} from '../../stores/store-b2b.ts';
import type {Link} from '../element-footer.ts';
import {
  createStaticPageUrl,
  createStaticPageUrlB2C,
} from '../../urlHandling/urlCreate.ts';

interface TitleModuleOptions {
  href?: string;
  clickFunction?: (evt: MouseEvent) => void;
  title: string;
  arrow?: 'regular' | 'reverse';
  type?: 'title' | 'subtitle' | 'sub-subtitle';
  border?: boolean;
}

@customElement('element-b2b-menu')
export default class ElementB2bMenu extends LitElement {
  @state() mobileView = false; // true if window width less than 820px (keep value same as in tailwind config)
  @state() largeView = false; // true if window width more than 1239px (keep value same as in tailwind config)
  @state() menuLevel = 0;
  @state() secondLevelMenu: B2BStaticPage;
  @state() thirdLevelMenu: B2BModuleInfo;
  @state() B2BHierarchy: B2BStaticPage[];
  @state() languageCode = getLanguageCode();
  private nestedPages: B2BStaticPage[];
  private flatPages: B2BStaticPage[];
  private resizeObserver: ResizeObserver;

  override async connectedCallback() {
    super.connectedCallback();
    const languageCode = getLanguageCode();
    this.B2BHierarchy = await fetchB2BHierarchy(languageCode);
    b2bPageHierarchy$.next(this.B2BHierarchy);
    this.nestedPages = this.B2BHierarchy.filter(
      (staticPage: B2BStaticPage) => staticPage.sp_summary,
    );
    this.flatPages = this.B2BHierarchy.filter(
      (staticPage: B2BStaticPage) => !staticPage.sp_summary,
    );

    this.resizeObserver = new ResizeObserver(() => {
      this.mobileView = window.matchMedia('(max-width: 820px)').matches;
      this.largeView = window.matchMedia('(min-width: 1239px)').matches;
    });
    this.resizeObserver.observe(document.body);
  }

  override render() {
    return html` <div
      class="h-screen-wo-header-b2b w-full bg-chm-red overflow-y-auto ${classMap(
        splitClassList({
          'p-[32px]': this.mobileView,
          'flex flex-col content-center pt-[100px]': this.largeView,
        }),
      )}"
    >
      ${this.mobileView
        ? this.renderSmallMenu()
        : this.largeView
          ? this.renderLargeMenu()
          : this.renderMediumMenu()}
      ${this.renderFooter()}
    </div>`;
  }

  /**
   * Generates anchor element for title, subtitle, or sub-subtitle for all menu levels.
   *
   * @param href The URL to navigate to when the link is clicked. Defaults to '#'.
   * @param clickFunction The function to execute when the link is clicked. Defaults closing and reseting the menu.
   * @param title The text content of the anchor element.
   * @param arrow The type of arrow to display ('regular' or 'reverse').
   * @param type The type of the title ('title', 'subtitle', 'sub-subtitle'). Defaults to 'title'.
   * @param border Whether to display a border at the top of the element. Defaults to true.
   * @return The HTML template for the anchor element.
   */
  titleModule({
    href = '#',
    clickFunction = () => {
      this.dispatchEvent(new CustomEvent('closeMenu'));
      this.secondLevelMenu = null;
      this.thirdLevelMenu = null;
      this.menuLevel = 0;
    },
    title = '',
    arrow,
    type = 'title',
    border = true,
  }: TitleModuleOptions) {
    const lang = getLanguageCode();
    return html`
      ${arrow === 'reverse'
        ? html`
            <a
              href="#"
              @click="${(evt: MouseEvent) => {
                evt.preventDefault();
                this.menuLevel -= 1;
              }}"
              class="${classMap(
                splitClassList({
                  'border-t-[1px]  border-white': border,
                  'pb-[32px]': type === 'title',
                }),
              )} flex place-content-center text-white w-[20%] pt-[20px] pl-[8px] mouse:hover:bg-chm-focus-red"
              >${breadcrumbArrowSvg('w-6 w-6 rotate-180 mr-4')}
            </a>
          `
        : nothing}
      <a
        href=${href === '#' ? href : `/${lang}/${href}`}
        @click="${clickFunction}"
        class="${classMap(
          splitClassList({
            'flex place-items-center justify-between': arrow === 'regular',
            'text-md-h1 lg:text-xxl-h1': type === 'title',
            'text-b2b-menu md:text-md-b2b-menu lg:text-lg-b2b-menu':
              type === 'subtitle' || type === 'sub-subtitle',
            'ml-[32px]': type === 'sub-subtitle',
            'border-t-[1px]  border-white': border,
            'pb-[32px]': type === 'title',
            'pb-[24px]': type !== 'title',
            'w-full': type !== 'sub-subtitle',
            'w-[calc(100%-32px)]': type === 'sub-subtitle',
          }),
        )} block text-white pt-[20px] pl-[8px] transition duration-300 mouse:hover:bg-chm-focus-red"
      >
        ${title}
        ${arrow === 'regular'
          ? breadcrumbArrowSvg('inline-block min-w-6 w-6 w-6 mr-2')
          : nothing}</a
      >
    `;
  }

  renderSmallMenu() {
    return html`
      <div
        class="w-full ${classMap({
          hidden: this.menuLevel !== 0,
          block: this.menuLevel === 0,
        })}"
      >
        ${this.nestedPages.map((staticPage: B2BStaticPage) => {
          return this.titleModule({
            clickFunction: (evt: MouseEvent) => {
              evt.preventDefault();
              this.secondLevelMenu = staticPage;
              this.menuLevel = 1;
            },
            title: staticPage.sp_title,
            arrow: 'regular',
          });
        })}
        ${this.flatPages.map((staticPage: B2BStaticPage) => {
          return this.titleModule({
            href: staticPage.key,
            title: staticPage.sp_title,
          });
        })}
      </div>
      ${this.secondLevelMenu ? this.renderSecondLevelMenu() : nothing}
      ${this.thirdLevelMenu ? this.renderThirdLevelMenu() : nothing}
      ${this.renderToTheRoutesButton()}
    `;
  }

  /**
   * Renders the first level menu for small and medium screens
   **/
  renderSecondLevelMenu() {
    return this.mobileView
      ? html` <div
          class="w-full ${classMap({
            hidden: this.menuLevel !== 1,
            block: this.menuLevel === 1,
          })}"
        >
          <div class="flex w-full">
            ${this.titleModule({
              href: this.secondLevelMenu.key,
              title: this.secondLevelMenu.sp_title,
              arrow: 'reverse',
            })}
          </div>
          ${this.secondLevelMenu.children?.map((moduleInfo: B2BModuleInfo) => {
            return moduleInfo.children.length > 0
              ? this.titleModule({
                  clickFunction: (evt: MouseEvent) => {
                    evt.preventDefault();
                    this.menuLevel = 2;
                    this.thirdLevelMenu = moduleInfo;
                  },
                  title: moduleInfo.sp_title,
                  arrow: 'regular',
                  type: 'subtitle',
                })
              : this.titleModule({
                  href: moduleInfo.key,
                  title: moduleInfo.sp_title,
                  type: 'subtitle',
                });
          })}
        </div>`
      : html`
          <div>
            ${this.titleModule({
              title: this.secondLevelMenu.sp_title,
              href: this.secondLevelMenu.key,
              border: false,
            })}
            ${this.secondLevelMenu.children.map((moduleInfo: B2BModuleInfo) => {
              return html`
                ${this.titleModule({
                  title: moduleInfo.sp_title,
                  href: moduleInfo.key,
                  type: 'subtitle',
                })}
                ${moduleInfo.children?.map((moduleInfo: B2BModuleInfo) => {
                  return this.titleModule({
                    title: moduleInfo.sp_title,
                    href: moduleInfo.key,
                    type: 'sub-subtitle',
                  });
                })}
              `;
            })}
          </div>
        `;
  }

  /**
   * Renders the third level menu only for small screens
   **/
  renderThirdLevelMenu() {
    return html`
      <div
        class="w-full ${classMap({
          hidden: this.menuLevel !== 2,
          block: this.menuLevel === 2,
        })}"
      >
        <div class="flex w-full">
          ${this.titleModule({
            href: this.thirdLevelMenu.key,
            title: this.thirdLevelMenu.sp_title,
            arrow: 'reverse',
          })}
        </div>
        ${this.thirdLevelMenu.children.map((moduleInfo: B2BModuleInfo) => {
          return this.titleModule({
            href: moduleInfo.key,
            title: moduleInfo.sp_title,
            type: 'subtitle',
          });
        })}
      </div>
    `;
  }

  renderMediumMenu() {
    return html`
      <div class="flex w-content mx-auto mt-[60px]">
        <div class="w-[310px] shrink-0">
          ${this.nestedPages?.map((staticPage: B2BStaticPage) => {
            return this.titleModule({
              clickFunction: (evt: MouseEvent) => {
                evt.preventDefault();
                this.secondLevelMenu = staticPage;
                this.menuLevel = 1;
              },
              title: staticPage.sp_title,
              arrow: 'regular',
            });
          })}
          ${this.flatPages?.map((staticPage: B2BStaticPage) => {
            return this.titleModule({
              href: staticPage.key,
              title: staticPage.sp_title,
            });
          })}
          ${this.renderToTheRoutesButton()}
        </div>
        <!-- this div is to handle the space that can't be above 131px -->
        <div class="max-w-[131px] w-full shrink"></div>
        <div class="w-[310px] shrink-0">
          ${this.secondLevelMenu ? this.renderSecondLevelMenu() : nothing}
        </div>
      </div>
    `;
  }

  renderLargeMenu() {
    return html` <div class="grid grid-cols-4 gap-x-[24px] w-content mx-auto">
      <div class="col-span-full grid grid-cols-4 gap-x-[24px]">
        ${this.nestedPages.map((staticPage: B2BStaticPage, index: number) => {
          return html`
            <div class="col-span-1">
              ${this.titleModule({
                title: staticPage.sp_title,
                href: staticPage.key,
                border: false,
              })}
            </div>
          `;
        })}
      </div>
      <div class="col-span-full grid grid-cols-4 gap-x-[24px]">
        ${this.nestedPages.map((staticPage: B2BStaticPage, index: number) => {
          return html`
            <div class="col-span-1">
              <p
                class="text-lg-b2b-menu pl-[8px] text-white mt-[16px] mb-[60px] break-words"
              >
                ${staticPage.sp_summary}
              </p>
            </div>
          `;
        })}
      </div>
      <div class="col-span-full grid grid-cols-4 gap-x-[24px]">
        ${this.nestedPages.map((staticPage: B2BStaticPage, index: number) => {
          return html`
            <div class="col-span-1">
              ${staticPage.children.map((moduleInfo: B2BModuleInfo) => {
                return html`
                  ${this.titleModule({
                    title: moduleInfo.sp_title,
                    href: moduleInfo.key,
                    type: 'subtitle',
                  })}
                  ${moduleInfo.children?.map((moduleInfo: B2BModuleInfo) => {
                    return this.titleModule({
                      title: moduleInfo.sp_title,
                      href: moduleInfo.key,
                      type: 'sub-subtitle',
                    });
                  })}
                `;
              })}
              ${this.flatPages && index === 0
                ? html`
                    ${this.flatPages.map((staticPage: B2BStaticPage) => {
                      return this.titleModule({
                        title: staticPage.sp_title,
                        href: staticPage.key,
                        border: false,
                      });
                    })}
                    ${this.renderToTheRoutesButton()}
                  `
                : nothing}
            </div>
          `;
        })}
      </div>
    </div>`;
  }

  private b2bMenuSources: Link[] = [
    {
      href: this.createSPUrl({
        en: 'contact',
        de: 'kontakt',
        fr: 'contact',
        it: 'contatto',
      }),
      title: () => msg('Contact'),
      onclick: () => this.dispatchEvent(new CustomEvent('closeMenu')),
    },
    {
      href: this.createSPUrlB2C({
        en: 'copyright',
        de: 'copyright',
        fr: 'copyright',
        it: 'copyright',
      }),
      title: () =>
        msg('Privacy policy', {
          id: 's66a8ea9d4609abf8',
          desc: 'big-menu-b2b',
        }),
      onclick: () => this.dispatchEvent(new CustomEvent('closeMenu')),
      target: '_blank',
    },
    {
      href: this.createSPUrlB2C({
        en: 'publishing-credits',
        de: 'impressum',
        fr: 'impressum',
        it: 'sigla-editoriale',
      }),
      title: () => msg('Publishing credits'),
      onclick: () => this.dispatchEvent(new CustomEvent('closeMenu')),
      target: '_blank',
    },
  ];

  createSPUrl(r: Record<LanguageCode, string>): string {
    return createStaticPageUrl(this.languageCode, r[this.languageCode]);
  }

  // create a static page URL for B2C website
  createSPUrlB2C(r: Record<LanguageCode, string>): string {
    return createStaticPageUrlB2C(this.languageCode, r[this.languageCode]);
  }

  renderFooter() {
    const itemsClasses =
      'flex flex-col lg:flex-row gap-[20px] md:w-[310px] lg:w-auto lg:gap-[32px] place-items-start lg:place-items-center mt-[32px] lg:mt-0';
    return html`
      <div
        class="footer-subtitle text-white flex flex-col lg:flex-row lg:col-span-4 justify-between mb-[28px] h-fit md:mb-[80px] ${classMap(
          splitClassList({
            'w-content mx-auto': !this.mobileView,
            hidden: this.menuLevel !== 0 && this.mobileView,
          }),
        )}"
      >
        <div class="${itemsClasses}">
          ${this.b2bMenuSources.map((src) => {
            return html`<a
              class="flex h-full transition duration-300 items-center px-[10px] py-[4px] mouse:hover:bg-chm-focus-red mouse:hover:text-white"
              href="${src.href}"
              target="${ifDefined(src.target)}"
              @click="${src.onclick}"
              >${src.title()}</a
            >`;
          })}
        </div>
        <div
          class="${classMap(
            splitClassList({
              'mt-[60px]': !this.largeView,
              [itemsClasses]: true,
            }),
          )}"
        >
          <span class="px-[10px]"
            >${msg('Follow-us on:', {
              id: 'scab783c633cd38d3',
              desc: 'big-menu-b2b',
            })}</span
          >
          <element-b2b-socials
            @click="${() => this.dispatchEvent(new CustomEvent('closeMenu'))}"
          ></element-b2b-socials>
        </div>
      </div>
    `;
  }

  renderToTheRoutesButton() {
    return html`
      <div class="mt-[16px] mb-[32px] lg:mt-[32px] lg:mt-[40px]">
        <element-button
          type="secondaryLightB2B"
          .label="${() =>
            html`${arrowSvg({
              classes:
                'inline-block fill-chm-red mouse:group-hover:fill-white transition-colors ease-out duration-300 w-[24px] h-[24px] mr-[12px]',
            })}
            ${msg('to the routes')}`}"
          @click=${() => {
            window.open(`https://schweizmobil.ch/${this.languageCode}`);
          }}
        ></element-button>
      </div>
    `;
  }

  override createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'element-b2b-menu': ElementB2bMenu;
  }
}
