import type {
  AbstractMixin,
  CopyRightMixin,
  MultiUrlMixin,
  UrlMixin,
} from './api-mixins.ts';
import type {LanguageCode} from '../i18n.ts';
import {WEBDB_S3_URL} from '../envs.ts';
import type {Gallery} from '../apis/api-common.ts';

export const fileExtension = [
  'pdf',
  'doc',
  'xlsx',
  'ppt',
  'zip',
  'png',
  'jpg',
  'eps',
] as const;

export type FileType = (typeof fileExtension)[number];

const fileExtensionRegex = new RegExp(`.(${fileExtension.join('|')})$`, 'i');

export type ModuleInfoSvg = {
  name?: string;
  abstract?: string;
  place?: boolean;
  title?: string;
  url?: string;
  urlSmall?: string;
  urlSvg: string;
};

export type ModuleInfoFiles = {
  name: string;
  fileTitle: Record<LanguageCode, string | null>;
  fileUrl: Record<LanguageCode, string | null>;
  type: FileType;
  fileDescription?: Record<LanguageCode, string | null>;
  tags?: FilesTags[];
  year?: string;
  fileSize?: string;
};

export type FilesTags = {
  name: string;
  category: string;
};

interface ModuleInfosBase {
  type:
    | 'classic'
    | 'video'
    | 'title_text_photo'
    | 'title_text_photo_small'
    | 'files'
    | 'link'
    | 'title'
    | 'tiles';
  title: string;
  titleDisplay: string;
  pictureImages: (MultiUrlMixin & CopyRightMixin & AbstractMixin)[] | Gallery[];
  content?: string;
  urlLink?: string;
  urlText?: string;
  isSlide?: boolean;
}

export interface ModuleInfos extends ModuleInfosBase {
  pictureLogos: UrlMixin[];
  publicationDate?: string;
  file?: UrlMixin;
  dividingLine?: boolean;
  isCollapsable?: boolean;
  urlVideo?: string;
  imagePosition?: 'left' | 'right';
  targetType?: 'page' | 'hyperlink';
  page?: {titleShort: Record<string, string>; title: string};
  displayFilter?: boolean;
  files?: ModuleInfoFiles[];
  svgs?: ModuleInfoSvg[];
}

export interface SlideModuleInfos extends ModuleInfosBase {
  targetType?: string;
}

export interface StaticPage {
  kind: 'static_page';
  title: string;
  titleShort: Record<LanguageCode, string>;
  moduleInfos: ModuleInfos[];
  slideInfoModules?: SlideModuleInfos[];
}

export interface Topic {
  kind: 'topic';
  title: string;
  titleShort: Record<LanguageCode, string>;
  staticPages: StaticPage[];
}

export function removeExtension(fileName: string): string {
  return fileName.replace(fileExtensionRegex, '');
}

export async function fetchStaticPageOrTopic(
  key: string,
  lang: LanguageCode,
  b2b: boolean = false,
): Promise<StaticPage | Topic> {
  const propagatedParams =
    typeof document !== 'undefined' ? document.location.search : '';
  const mergedUrl = b2b
    ? `${WEBDB_S3_URL}/b2b_merged/${lang}/${key}.json${propagatedParams}`
    : `${WEBDB_S3_URL}/merged/${lang}/${key}.json${propagatedParams}`;
  return fetch(mergedUrl).then((r) => (r.ok ? r.json() : null));
}

export function isTopic(spt: StaticPage | Topic): spt is Topic {
  return spt.kind === 'topic';
}

export function isStaticPage(spt: StaticPage | Topic): spt is StaticPage {
  return spt.kind === 'static_page';
}

interface TabHash {
  selectedTab: number;
  hash: string;
}

export function findTopicTabFromHash(
  spt: Topic,
  hash: string,
  lang: LanguageCode,
): TabHash {
  // fixme some title has space at the end. We can remove it when data will be fixed
  const staticPageKey = decodeURI(hash?.replace('#', '').replace(' ', ''));
  if (staticPageKey && staticPageKey.length) {
    const index = spt.staticPages.findIndex((sp) => {
      // fixme some title has space at the end. We can remove it when data will be fixed
      const titles = Object.values(sp.titleShort).map((t) => {
        return t.replace(' ', '');
      });
      return titles.includes(staticPageKey);
    });
    if (index > -1) {
      // fixme some title has space at the end. We can remove it when data will be fixed
      const newHash = `#${spt.staticPages[index].titleShort[lang].replace(
        ' ',
        '',
      )}`;
      return {
        selectedTab: index,
        hash: newHash,
      };
    }
  }
  return null;
}
