import {customElement} from 'lit/decorators.js';
import {LitElement, html} from 'lit';
import {
  facebookSvg,
  instagramSvg,
  linkedInSvg,
  youtubeSvg,
} from '../../helpers/elements.ts';
import {msg} from '@lit/localize';

const socialsIconClasses = 'w-[24px] h-[24px]';

const SOCIALS = [
  {
    url: 'https://instagram.com/schweizmobil/',
    svg: instagramSvg(socialsIconClasses),
  },
  {
    url: 'https://facebook.com/SchweizMobil/',
    svg: facebookSvg(socialsIconClasses),
  },
  {
    url: 'https://ch.linkedin.com/company/schweizmobil',
    svg: linkedInSvg(socialsIconClasses),
  },
  {
    url: 'https://youtube.com/@stiftungschweizmobil5551',
    svg: youtubeSvg(socialsIconClasses),
  },
];

@customElement('element-b2b-socials')
export class ElementB2bSocials extends LitElement {
  override render() {
    return html`
      <div class="flex h-[40px] items-center gap-x-[14px]">
        ${SOCIALS.map(
          (social) => html`
            <a
              class="fill-white rounded-full transition duration-300 mouse:hover:bg-chm-focus-red p-[8px]"
              href=${social.url}
              target="_blank"
            >
              ${social.svg}
              <span class="screen-reader-only"
                >${msg('(opens in a new tab)')}</span
              >
            </a>
          `,
        )}
      </div>
    `;
  }
  override createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'element-b2b-socials': ElementB2bSocials;
  }
}
