/**
 * The value of CSS vh (vertical height) is not consistent between
 * mobile browsers. Some are wrong when there is an address bar / scroll bar, ...
 * The code here defines a --vh custom CSS variable to be used in CSS code.
 * Warning: the resize can be a performance issue.
 */
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

export function initializeViewHeight() {
  setViewHeight();
  window.addEventListener('resize', () => {
    setTimeout(setViewHeight, 500);
  });
}

function setViewHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
