import {html, LitElement} from 'lit';
import {customElement, state} from 'lit/decorators.js';
import {localized, msg} from '@lit/localize';

import {crossSvg, menuSvg, searchSvg} from '../../helpers/elements.ts';
import {getLanguageCode} from '../../i18n.ts';
import {resolveDeployPrefix} from '../../initialization/deployPrefixResolver.ts';
import '../components/element-button.ts';
import {classMap} from 'lit/directives/class-map.js';
import './element-b2b-menu.ts';
import './element-b2b-search.ts';

const deployPrefix = resolveDeployPrefix();

@localized()
@customElement('b2b-element-header')
export class B2bElementHeader extends LitElement {
  @state() searchActive = false;
  @state() menuOpened = false;
  @state() redBackground = false;

  constructor() {
    super();
  }

  override async connectedCallback() {
    super.connectedCallback();
  }

  renderSearch() {
    return html`<element-b2b-search
      class="flex"
      @close="${() => (this.searchActive = false)}"
    ></element-b2b-search>`;
  }

  renderHeader() {
    const languageCode = getLanguageCode();
    const iconClasses = `w-header-icon-s md:w-header-icon-m xxl:w-header-icon-l mx-[12px] my-[8px] ${
      this.redBackground
        ? 'fill-white mouse:group-hover:fill-chm-focus-white'
        : 'fill-black mouse:group-hover:fill-chm-focus-red'
    }`;
    const whitePrefix = this.redBackground ? 'w_' : '';
    return html`
      <div class="flex transition duration-300 justify-center h-header-zero md:h-header-sm xxl:h-header-mdxx shadow-header ${classMap(
        {
          'bg-white': !this.redBackground,
          'bg-chm-red': this.redBackground,
        },
      )}">
        <div id='headerDiv' class="flex items-center justify-between w-content  md:gap-x-[32px] xxl:gap-x-[40px]">
          <a href="/${languageCode}"
            @click=${() => {
              this.menuOpened = false;
              this.redBackground = false;
            }}
            class="min-w-logo-chm-s sm:min-w-logo-chm-m mdxx:min-w-logo-chm-l">
            <img class="hidden md:block h-[54px] xxl:h-[65px] w-auto aspect-[209/27] xxl:aspect-[503/65]"
                 src="${deployPrefix}/img/logo_b2b/${whitePrefix}sm_b2b_logo_${languageCode}.svg?ts11=TIMESTAMP11" alt="${msg(
                   'SwitzerlandMobility',
                 )}">
            <img class="block md:hidden h-[38px] aspect-[97/38]"
                 src="${deployPrefix}/img/logo_b2b/${whitePrefix}s_sm_b2b_logo.svg?ts11=TIMESTAMP11"
                 alt="logo" width="97" height="38">
          </a>
          <div class="flex items-center gap-x-[2px] md:gap-x-[32px] xxl:gap-x-[40px]">
          <button class="group rounded-lg transition-all duration-300 ${classMap({'mouse:hover:bg-chm-focus-red': this.redBackground})}" @click="${() => {
            this.searchActive = true;
            this.menuOpened = false;
            this.redBackground = false;
          }}" aria-label="${msg('Search…')}">
            ${searchSvg(iconClasses)}
          </button>
          <button class="flex group items-center rounded-lg mouse:hover:bg-white justify-center ${classMap({hidden: this.menuOpened})}"
                  @click=${() => {
                    this.menuOpened = !this.menuOpened;
                    this.redBackground = !this.redBackground;
                  }}
                  aria-label="${msg('Menu', {desc: 'b2b'})}">
            <span class="font-bold hidden md:block ml-[12px] mouse:group-hover:text-chm-focus-red ${classMap({'text-white': this.redBackground})}">${msg('Menu', {desc: 'b2b'})}</span>
            ${menuSvg(iconClasses)}
          </button>
          <button class="flex group transition duration-300 items-center rounded-lg mouse:hover:bg-chm-focus-red justify-center ${classMap({hidden: !this.menuOpened})}"
                  @click=${() => {
                    this.menuOpened = !this.menuOpened;
                    this.redBackground = !this.redBackground;
                  }}
                  aria-label="${msg('Close menu', {desc: 'b2b: close menu aria label'})}">
            ${crossSvg(iconClasses)}
          </button>
          </div>
          </div>
        </div>
        <element-b2b-menu
          @closeMenu=${() => {
            this.menuOpened = !this.menuOpened;
            this.redBackground = !this.redBackground;
          }}
         class="${classMap({'hide-menu': !this.menuOpened, 'show-menu': this.menuOpened})} transition-[opacity,visibility] ease-in-out duration-300 absolute w-screen top-header-zero md:top-header-sm xxl:top-header-mdxx z-[40]"></element-b2b-menu>
      </div>
    `;
  }

  override render() {
    return this.searchActive ? this.renderSearch() : this.renderHeader();
  }

  override createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'b2b-element-header': B2bElementHeader;
  }
}
