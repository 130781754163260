import type {Context, Route} from '@vaadin/router';
import {resolveDeployPrefix} from './initialization/deployPrefixResolver.ts';
import {monitorLoadingError} from './initialization/obsoleteApi.ts';
import type {LanguageCode} from './i18n.ts';
import {b2bLanguageCodes} from './i18n.ts';
import {fetchStaticPageOrTopic} from './apis/api-static-pages-and-topics.ts';
import {stpShortTitles$} from './stores/store-main.ts';

const deployPrefix = resolveDeployPrefix();
const langPart = `:lang(${b2bLanguageCodes.join('|')})`;

export const routes: Route[] = [
  {
    path: `${deployPrefix}/${langPart}`,
    action: async () => {
      const {PageB2bHome} = await monitorLoadingError(
        import('./pages/page-b2b-home.ts'),
      );

      const page = new PageB2bHome();
      return page;
    },
  },
  // all non-wildcards should be added above
  {
    path: `${deployPrefix}/${langPart}/:key(.+)`,
    action: async (context: Context) => {
      // The topics and static pages URLs are not known in advance. As a consequence
      // we tentatively fetch them in this fallback case.
      const lang = context.params['lang'] as LanguageCode;
      const key = context.params['key'] as string;
      try {
        const spt = await fetchStaticPageOrTopic(key, lang, true);
        stpShortTitles$.next(spt.titleShort);
        const {PageStaticOrTopic} = await monitorLoadingError(
          import('./pages/page-static-or-topic.ts'),
        );
        const sptElement = new PageStaticOrTopic();
        sptElement.spt = spt;

        switch (spt.kind) {
          case 'static_page': {
            sptElement.selectedTab = 0;
            break;
          }
        }
        return sptElement;
      } catch {
        const {PageNotFound} = await monitorLoadingError(
          import('./pages/page-not-found.ts'),
        );
        return new PageNotFound();
      }
    },
  },
  {
    path: `${deployPrefix}/(.*)`,
    action: async () => {
      throw new Error('page not found');
      // FIXME: same not found page as in the newui ?
    },
  },
];
